define("ember-tutorial/helpers/index-images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function indexImages(params
  /*, hash*/
  ) {
    //console.log(params[1][params[0]-1]);
    var imgsrc = params[1][params[0] - 1];
    var noTag = params[3]; //return noTag;

    if (imgsrc != "") {
      if (noTag == 'yes') return imgsrc;else return '<img  class="' + params[2] + '" src="' + imgsrc + '" ' + params[3] + ' />';
    }
  });

  _exports.default = _default;
});