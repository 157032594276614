define("ember-tutorial/templates/locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xHY5kseG",
    "block": "[[[1,[28,[35,0],[\"Locations\"],null]],[1,\"\\n\\n\\n\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"col s12 m12\"],[12],[1,\"\\n\"],[10,\"h2\"],[12],[1,\"list of locations\"],[13],[1,\"\\n\"],[10,\"ul\"],[12],[1,[34,1]],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1]],null]],null],null,[[[1,\"\\t\"],[10,\"li\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null],[1,\"\\t\"],[13],[1,\"\\n\\n\"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\t\\t\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"@model\",\"location\"],false,[\"page-title\",\"locations\",\"each\",\"-track-array\",\"component\",\"-outlet\"]]",
    "moduleName": "ember-tutorial/templates/locations.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});