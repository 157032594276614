define("ember-tutorial/components/core/multi-range-input", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="slider-range">
    <div>
      <div inverse-left style={{this.getLeftWidth}}></div>
      <div inverse-right style={{this.getRightWidth}}></div>
      <div range style={{this.getRange}}></div>
      <span thumb style={{this.getThumb1}}></span>
      <span thumb style={{this.getThumb2}}></span>
    </div>
    <Input type="range" tabindex="0" max={{this.max}} min={{@min}} step={{@step}} @value={{this.leftValue}} {{on "input" this.leftValueChange}} />
    <Input type="range" tabindex="0" max={{this.max}} min={{@min}} step={{@step}} @value={{this.rightValue}} {{on "input" this.rightValueChange}}  />
  </div>
  */
  {
    "id": "xUfDsI+0",
    "block": "[[[10,0],[14,0,\"slider-range\"],[12],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,0],[14,\"inverse-left\",\"\"],[15,5,[30,0,[\"getLeftWidth\"]]],[12],[13],[1,\"\\n    \"],[10,0],[14,\"inverse-right\",\"\"],[15,5,[30,0,[\"getRightWidth\"]]],[12],[13],[1,\"\\n    \"],[10,0],[14,\"range\",\"\"],[15,5,[30,0,[\"getRange\"]]],[12],[13],[1,\"\\n    \"],[10,1],[14,\"thumb\",\"\"],[15,5,[30,0,[\"getThumb1\"]]],[12],[13],[1,\"\\n    \"],[10,1],[14,\"thumb\",\"\"],[15,5,[30,0,[\"getThumb2\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,0],[[24,\"tabindex\",\"0\"],[16,\"max\",[30,0,[\"max\"]]],[16,\"min\",[30,1]],[16,\"step\",[30,2]],[24,4,\"range\"],[4,[38,1],[\"input\",[30,0,[\"leftValueChange\"]]],null]],[[\"@value\"],[[30,0,[\"leftValue\"]]]],null],[1,\"\\n  \"],[8,[39,0],[[24,\"tabindex\",\"0\"],[16,\"max\",[30,0,[\"max\"]]],[16,\"min\",[30,1]],[16,\"step\",[30,2]],[24,4,\"range\"],[4,[38,1],[\"input\",[30,0,[\"rightValueChange\"]]],null]],[[\"@value\"],[[30,0,[\"rightValue\"]]]],null],[1,\"\\n\"],[13]],[\"@min\",\"@step\"],false,[\"input\",\"on\"]]",
    "moduleName": "ember-tutorial/components/core/multi-range-input.hbs",
    "isStrictMode": false
  });

  let MultiRangeInputComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class MultiRangeInputComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "max", 100);

      _initializerDefineProperty(this, "leftValue", _descriptor, this);

      _initializerDefineProperty(this, "rightValue", _descriptor2, this);
    }

    get getRange() {
      return `left:${this.leftValue}%; right:${this.max - this.rightValue}%`;
    }

    get getThumb1() {
      return `left:${this.leftValue}%`;
    }

    get getThumb2() {
      return `left:${this.rightValue}%`;
    }

    get getLeftWidth() {
      return `width:${this.leftValue}%`;
    }

    get getRightWidth() {
      return `width:${this.max - this.rightValue}%`;
    }

    leftValueChange(event) {
      let value = parseInt(event.target.value);

      if (value < this.rightValue) {
        this.leftValue = value;
      } else {
        if (this.rightValue == this.args.max || this.rightValue == 0) {
          this.leftValue = this.rightValue;
        } else {
          this.leftValue = this.rightValue - 1;
        }
      }

      this.args.onChange([this.leftValue * this.args.max / 100, this.rightValue * this.args.max / 100]);
    }

    rightValueChange(event) {
      let value = parseInt(event.target.value);

      if (value > this.leftValue) {
        this.rightValue = value;
      } else {
        if (this.leftValue == this.args.max || this.leftValue == 0) {
          this.rightValue = this.leftValue;
        } else {
          this.rightValue = this.leftValue - 1;
        }
      }

      this.args.onChange([this.leftValue * this.args.max / 100, this.rightValue * this.args.max / 100]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "leftValue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rightValue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "leftValueChange", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "leftValueChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rightValueChange", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "rightValueChange"), _class.prototype)), _class));
  _exports.default = MultiRangeInputComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MultiRangeInputComponent);
});