define("ember-tutorial/routes/lotm", ["exports", "axios"], function (_exports, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LotmRoute extends Ember.Route {}

  _exports.default = LotmRoute;
});