define("ember-tutorial/components/nav-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
      <!--nav style='background-color: none; ' role="navigation">
      <div class="nav-wrapper container">
        {{#link-to "home" class="brand-logo" id="logo-container"}}<img src="http://emb.theonsitemanager.com.au/assets/logo.png"> {{/link-to}}
    	<ul class="right hide-on-med-and-down">
          <li><a href="#"  class="black-text"><span class="fas fa-phone"></span> Support: 07 3868 4047</a></li>
       </ul>
  		
        <ul class="right hide-on-med-and-down">
          <li><a href="#"  class="black-text"><span class="fas fa-sign-in-alt"></span> Log In</a></li>
        </ul>
        <ul class="right hide-on-med-and-down">
          <li ><a href="#" class="black-text"><span class="fas fa-user-plus"></span> Join</a></li>
        </ul>		
  
  		
  	<ul class="right hide-on-med-and-down">
          <li><a href="#" class="black-text"><span class="far fa-heart"></span> My Shortlist</a></li>
        </ul>
  		
        <ul id="nav-mobile" class="sidenav">
          <li><a href="#"  class="black-text">Navbar Link</a></li>
        </ul>
        <a href="#" data-target="nav-mobile" class="sidenav-trigger"><i class="material-icons">menu</i></a>
      </div>
    </nav-->
  
  
   
  
  {{yield}}
  */
  {
    "id": "4iKSiNFO",
    "block": "[[[1,\"  \"],[3,\"nav style='background-color: none; ' role=\\\"navigation\\\">\\n    <div class=\\\"nav-wrapper container\\\">\\n      {{#link-to \\\"home\\\" class=\\\"brand-logo\\\" id=\\\"logo-container\\\"}}<img src=\\\"http://emb.theonsitemanager.com.au/assets/logo.png\\\"> {{/link-to}}\\n  \\t<ul class=\\\"right hide-on-med-and-down\\\">\\n        <li><a href=\\\"#\\\"  class=\\\"black-text\\\"><span class=\\\"fas fa-phone\\\"></span> Support: 07 3868 4047</a></li>\\n     </ul>\\n\\t\\t\\n      <ul class=\\\"right hide-on-med-and-down\\\">\\n        <li><a href=\\\"#\\\"  class=\\\"black-text\\\"><span class=\\\"fas fa-sign-in-alt\\\"></span> Log In</a></li>\\n      </ul>\\n      <ul class=\\\"right hide-on-med-and-down\\\">\\n        <li ><a href=\\\"#\\\" class=\\\"black-text\\\"><span class=\\\"fas fa-user-plus\\\"></span> Join</a></li>\\n      </ul>\\t\\t\\n\\n\\t\\t\\n\\t<ul class=\\\"right hide-on-med-and-down\\\">\\n        <li><a href=\\\"#\\\" class=\\\"black-text\\\"><span class=\\\"far fa-heart\\\"></span> My Shortlist</a></li>\\n      </ul>\\n\\t\\t\\n      <ul id=\\\"nav-mobile\\\" class=\\\"sidenav\\\">\\n        <li><a href=\\\"#\\\"  class=\\\"black-text\\\">Navbar Link</a></li>\\n      </ul>\\n      <a href=\\\"#\\\" data-target=\\\"nav-mobile\\\" class=\\\"sidenav-trigger\\\"><i class=\\\"material-icons\\\">menu</i></a>\\n    </div>\\n  </nav\"],[1,\"\\n\\n\\n \\n\\n\"],[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-tutorial/components/nav-bar.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});