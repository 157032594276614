define("ember-tutorial/routes/home", ["exports", "axios"], function (_exports, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HomeRoute extends Ember.Route {
    async model() {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      var theMonth = currentMonth + 1;
      let data = await _axios.default.get('http://theonsitemanager.com.au/api/v2/LoTM.php?date=' + theMonth + '/01/' + currentYear).then(response => response.data).then(data => {
        return _axios.default.get('http://theonsitemanager.com.au/api/v2/listing_public.php?id=' + data.data.attributes.pageId);
      }).catch(error => {
        console.log(error);
        return [];
      });
      console.log(data.data.data.attributes); //return data

      return [data.data.data.attributes];
    }

  }

  _exports.default = HomeRoute;
});