define("ember-tutorial/router", ["exports", "ember-tutorial/config/environment", "axios"], function (_exports, _environment, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

    model() {
      console.log("test " + Date().getFullYear());
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('locations');
    this.route('listing-mmr');
    this.route('listing-mr');
    this.route('lotm');
    this.route('home');
    this.route('management-rights', {
      path: '/management-rights/:location'
    });
  });
  $(document).ready(function () {
    $('input.autocomplete').autocomplete({
      data: {
        "Apple": null,
        "Microsoft": null,
        "Google": 'https://placehold.it/250x250'
      }
    });
  });
});