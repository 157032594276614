define("ember-tutorial/helpers/number-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function numberFormat(params
  /*, hash*/
  ) {
    if (params[1] == undefined) var prefix = '';else var prefix = params[1];
    var num_parts = params[0].toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return prefix + " " + num_parts.join(".");
  });

  _exports.default = _default;
});