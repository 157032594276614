define("ember-tutorial/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends Ember.Route {
    beforeModel() {
      this.replaceWith('home');
    }

  }

  _exports.default = IndexRoute;
});