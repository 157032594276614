define("ember-tutorial/routes/listing-mr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ListingMrRoute extends Ember.Route {}

  _exports.default = ListingMrRoute;
});