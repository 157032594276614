define("ember-tutorial/routes/locations", ["exports", "axios"], function (_exports, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LocationsRoute extends Ember.Route {
    model() {
      _axios.default.get('http://theonsitemanager.com.au/test2.php').then(function (response) {
        console.log(response.data.brisbane[0]);
        return response.data.brisbane[0];
      }); //return response.data;
      //return ["brisbane", "sydney", "melbourne"]

    }

  } //axios.get( url:'http://theonsitemanager.com.au/test2.php' );
  //	.then(function(response : AxiosResponse<any> ){
  //		console.log(response);

  /*return fetch('http://theonsitemanager.com.au/test2.php').then(
  	response => {
  		return response;
  		
  	}
  );
  
  		
  let locationsX = [
  	{
  		name: "redcliffe",
  		postcode: "4020"
  	},
  	{
  		name: "hendra",
  		postcode: "4011"
  	}
  ]; 
  return locations;*/


  _exports.default = LocationsRoute;
});